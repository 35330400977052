.snackbar {
	display: flex;
	align-items: center;
	justify-content: space-between;
	position: fixed;
	width: 90vw;
	top: 3vh;
	right: 3vw;
	transform: translateY(-300vh);
	padding: 0.5rem;
	background-color: white;
	box-shadow: 1px 1px 2vh 0 rgba(0, 0, 0, 0.25);

	transition: transform 0.6s cubic-bezier(0.87, 0, 0.13, 1);

	@media (min-width: 40rem) {
		width: 50vw;
		transform: translateX(300vh);
	}

	@media (min-width: 75rem) {
		width: 30vw;
	}

	.snackbar__icon {
		flex-shrink: 0;
		margin: 0 1rem;
	}

	p {
		display: flex;
		flex-wrap: wrap;
	}

	.snackbar__close {
		flex-shrink: 0;
		margin-right: 1rem;
		margin-left: 0.5rem;
		padding: 0.5rem;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		transition: background-color 0.5s;

		@media (min-width: 75rem) {
			&:hover {
				cursor: pointer;
			}
		}

		&:active {
			background-color: #ccc;
		}
	}
}
.snackbar--success {
	border-bottom: 5px solid #c0ca33;
	// background-color: #feffef;
}

.snackbar--error {
	border-bottom: 5px solid #f44336;
	// background-color: #ffe3e1;
}

.snackbar--warning {
	border-bottom: 5px solid #ffa12f;
	// background-color: #fdf1e2;
}

.snackbar--info {
	border-bottom: 5px solid #00b0ff;
	// background-color: #e0f4fd;
}

.snackbar__open {
	transform: translate(0, 0);
}
