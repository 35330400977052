.splashscreen__container {
	width: 100%;
	height: 100%;
	background: url("../images/Background_Image.png") no-repeat;
	background-size: cover;
	margin: 0;
	display: flex;
	align-items: flex-start;

	.splashcsreen__image-container {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 50vh;
		width: 100%;
	}

	@media (min-width: 40rem) {
    background: url("../images/Background_Image-desktop.png") no-repeat;
    // object-fit: cover;
	}
}
