.notifications__container {
	height: 100%;
	width: 100%;
	background-color: white;
	position: fixed;
	transform: translateX(-100%);
	transition: transform 0.4s;
	display: flex;
	flex-direction: column;

	&.notifications__container--active {
		transform: translateX(0);
	}

	@media (min-width: 40rem) {
		width: 50%;
		&.notifications__container--active {
			box-shadow: -6px 0 3vh 0 rgba(0, 0, 0, 0.2);
		}
	}

	@media (min-width: 75rem) {
		width: 25%;
	}

	.notifications__top-nav {
		display: flex;
		align-items: center;
		padding: 0 1.2rem;

		p {
			flex: auto;
			font-weight: 700;
			font-size: 1.5rem;
			color: $primary-color;
		}

		.notifications__close {
			display: flex;
			align-items: center;
			justify-content: flex-end;
			width: 100%;
			height: 5vh;

			.notifications__close--active {
				height: 6vh;
				width: 6vh;
				background-color: white;
				border-radius: 50%;
				display: flex;
				justify-content: center;
				align-items: center;
				transition: background-color 0.2s;

				@media (min-width: 75rem) {
					&:hover {
						cursor: pointer;
					}
				}

				&:active {
					background-color: #ccc;
				}
			}
		}
	}

	.notifications__content {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 100%;
	}
}
