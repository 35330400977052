.auth__container {
	display: flex;
	width: calc(100% - 2rem);
	height: calc(100% - 2rem);
	flex-direction: column;
	align-items: center;
	margin: 1rem;

	.auth__image-container {
		flex: 1;
		display: flex;
		align-items: center;
	}

	.auth__actions {
		flex: 1;
		display: flex;
		width: 100%;
		flex-direction: column;
		align-items: center;

		* {
			margin: 1rem 0;
		}
	}

	.actions {
		height: 100%;
		width: 100%;
		display: flex;
		flex-direction: column;
		// justify-content: space-around;
		align-items: center;

		.actions--top {
			display: flex;
			flex: 2;
			flex-direction: column;
			align-items: center;
			justify-content: center;

			> p {
				margin: 0;
			}
		}

		.actions--bottom {
			display: flex;
			flex: 3;
			flex-direction: column;
			align-items: center;
			width: 100%;

			> * {
				margin: 1rem 0;
			}

			p {
				font-size: 14px;
			}
		}
	}

	.auth__title {
		font-size: 2.3rem;
		font-weight: 700;
	}

	.auth__message {
		font-size: 14px;
	}

	.input-fields__container {
		display: block;
		width: 90%;
		@media (min-width: 40rem) {
			display: flex;
			flex-direction: column;
			width: auto;
		}
	}

	.input-text {
		width: calc(100% - 1.6rem);
		padding: 0.8rem;
		border: 1px solid $input-border-color;
		margin: 0.5rem 0;
		outline: none;
		background-color: white;
		transition: background-color 0.7s;

		&:focus {
			background-color: $input-border-color;
		}

		@media (min-width: 40rem) {
			width: 37rem;
		}
	}
}
