.recipe-car__container {
	display: flex;
	flex-wrap: wrap;

	.recipe-card {
		width: calc(100% - 1rem);
		box-shadow: 0 8px 2vh 1px rgba(0, 0, 0, 0.1);
		border-radius: 5px;
		margin: 0.75rem 0.5rem;
		transition: background-color 0.2s;

		&:active {
			background-color: #ccc;
		}

		&:last-child {
			margin-bottom: 1.5rem;
		}

		@media (min-width: 42rem) {
			width: calc(50% - 1rem);

			&:last-child {
				margin-bottom: 0.75rem;
			}
		}

		@media (min-width: 75rem) {
			width: calc(33.33% - 1rem);
			&:hover {
				cursor: pointer;
			}
		}

		> img {
			width: 100%;
		}

		.details {
			padding: 0 1rem;

			.category {
				color: $placeholder-color;
				text-transform: uppercase;
				font-size: 12px;
				line-height: 1.5rem;
			}

			.name {
				font-weight: 500;
				font-size: 18px;
				line-height: 1.5rem;
			}
		}
		.bottom-bar {
			display: flex;
			justify-content: flex-start;
			padding: 0.5rem 1rem 1rem 1rem;

			> * {
				display: flex;
				align-items: center;
				justify-content: flex-start;
				align-items: center;
				flex: 1.5;
			}

			.difficulty {
				flex: 1;
			}

			.bottom-bar--text {
				margin: 0;
				padding: 0 0.2rem;
				font-size: 14px;
				color: $placeholder-color;
				line-height: 1.5rem;
			}
		}
	}
}
