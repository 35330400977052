@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&display=swap");

@import "_variables.scss";

@import "_SplashScreen.scss";
@import "_AuthScreen.scss";
@import "_SnackBar.scss";
@import "_NavBar.scss";
@import "_Home.scss";
@import "_Profile.scss";
@import "_PageNotFound.scss";
@import "_RecipeCard.scss";
@import "_Notifications.scss";
@import "_Search.scss";
@import "_Categories.scss";
@import "_Favorites.scss";
@import "_RecipeDetails.scss";
@import "_CategoryDetails.scss";

html {
	height: 100%;
	width: 100%;
	// max-width: 100vw;
	// max-height: 100vh;
}

body {
	height: 100%;
	width: 100%;
	box-sizing: border-box;
	margin: 0;
	font-family: "Montserrat", sans-serif;
	user-select: none;
}

#root {
	height: 100%;
	width: 100%;
}

.app__container {
	width: 100%;
	height: 100%;
}

.dashboard__container {
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.dashboard__title {
	font-weight: bold;
	font-size: 18px;
}

.button {
	padding: 0.8rem;
	border-radius: 50px;
	border: none;
	outline: none;
	font-size: 16px;
	font-weight: 700;
	letter-spacing: 1px;
	width: 90%;
	height: 3rem;

	@media (min-width: 75rem) {
		&:hover {
			cursor: pointer;
		}
	}

	@media (min-width: 40rem) {
		width: 37rem;
	}
}

.button__filled {
	background-color: $primary-color;
	color: white;
	transition: background-color 0.2s;

	&:active {
		background-color: $primary-color-dark;
	}

	.button__loader {
		display: flex;
		justify-content: center;
		align-items: center;
	}
}

.button__outlined {
	border: 2px solid $primary-color;
	background-color: white;
	color: $primary-color;
	transition: border 0.2s, color 0.2s, background-color 0.2s;

	&:active {
		border: 2px solid $primary-color-dark;
		color: $primary-color-dark;
		background-color: $input-border-color;
	}
}

.goback {
	display: flex;
	align-items: center;
	width: 100%;
	height: 5vh;

	.goback__active {
		height: 6vh;
		width: 6vh;
		background-color: white;
		border-radius: 50%;
		display: flex;
		justify-content: center;
		align-items: center;
		transition: background-color 0.2s;

		@media (min-width: 75rem) {
			&:hover {
				cursor: pointer;
			}
		}

		&:active {
			background-color: #ccc;
		}
	}
}
