.home__container {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	height: calc(100% - 4.5rem);
	width: 100%;

	> * {
		padding: 0.5rem;
	}

	.home__top-nav {
		display: flex;
		align-items: center;
		box-shadow: 0 -3px 4vh 0 rgba(0, 0, 0, 0.15);

		> * {
			flex: 0.2;
		}

		p {
			flex: auto;
			text-align: center;
		}

		.home__notification-bell {
			display: flex;
			align-items: center;
			width: 100%;
			height: 5vh;

			.home__notification-bell--active {
				height: 6vh;
				width: 6vh;
				background-color: white;
				border-radius: 50%;
				display: flex;
				justify-content: center;
				align-items: center;
				transition: background-color 0.2s;

				@media (min-width: 75rem) {
					&:hover {
						cursor: pointer;
					}
				}

				&:active {
					background-color: #ccc;
				}
			}
		}

		.home__search {
			display: flex;
			align-items: center;
			width: 100%;
			height: 5vh;
			justify-content: flex-end;

			.home__search--active {
				height: 6vh;
				width: 6vh;
				background-color: white;
				border-radius: 50%;
				display: flex;
				justify-content: center;
				align-items: center;
				transition: background-color 0.2s;

				@media (min-width: 75rem) {
					&:hover {
						cursor: pointer;
					}
				}

				&:active {
					background-color: #ccc;
				}
			}
		}
	}

	.home__y-scroll {
		height: 100%;
		width: calc(100% - 1rem);
		overflow-y: scroll;
		overflow-x: hidden;
		-ms-overflow-style: none;
		scrollbar-width: none;

		&::-webkit-scrollbar {
			display: none;
		}

		@media (min-width: 81rem) {
			width: 80%;
			margin: auto;
		}

		.home__side-scroll {
			height: calc(25% + 4rem);
			width: 100%;
			min-height: 11rem;

			@media (min-width: 40rem) {
				height: calc(20% + 4rem);
			}

			.home__side-scroll--container {
				height: calc(100% - 4rem);
				// width: 100%;
				display: flex;
				overflow-x: scroll;
				overflow-y: hidden;
				padding: 2rem 0.5rem 1.5rem 0.5rem;
				-ms-overflow-style: none;
				scrollbar-width: none;

				&::-webkit-scrollbar {
					display: none;
				}

				.home__side-scroll--item {
					display: flex;
					justify-content: center;
					height: 100%;
					padding-right: 0.5rem;

					img {
						height: 100%;
					}
				}
			}
		}

		.home__recipe-cards {
			height: calc(75% - 5rem);
			width: 100%;
		}
	}
}
