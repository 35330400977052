.recipe-details__container {
	width: 100%;
	height: auto;
	display: flex;
	flex-direction: column;
	background-color: #f2f2f2;

	@media (min-width: 75rem) {
		flex-direction: row;
	}

	.recipe-details__header {
		width: 100%;
		height: 50vh;
		display: flex;
		flex-direction: column;
		flex: 1;

		@media (min-width: 75rem) {
			height: 100vh;
		}

		.recipe-details__header-top {
			width: 100%;
			height: 100%;
			display: flex;
			justify-content: space-between;
			flex: 3;

			@media (min-width: 75rem) {
				flex: 2;
			}

			> * {
				margin: 1.5rem;
				border-radius: 50%;
				background-color: rgba(0, 0, 0, 0.7);
				height: 2.4rem;
				width: 2.7rem;
				display: flex;
				justify-content: center;
				align-items: center;
				padding-top: 0.3rem;
				transition: background-color 0.5s;

				@media (min-width: 75rem) {
					&:hover {
						cursor: pointer;
					}
				}

				&:active {
					background-color: rgba(0, 0, 0, 0.3);
				}
			}
		}

		.recipe-details__header--bottom {
			display: flex;
			flex-direction: column;
			padding: 1rem;
			padding-bottom: 2rem;
			flex: 1;

			.recipe-details__details {
				padding: 0 1rem;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;

				> * {
					margin: 0.5rem;
				}

				.recipe-details__category {
					color: $placeholder-color;
					text-transform: uppercase;
					font-size: 16px;
					line-height: 1.5rem;
				}

				.recipe-details__name {
					font-weight: 500;
					font-size: 20px;
					line-height: 1.5rem;
				}
			}

			.recipe-details__bottom-bar {
				display: flex;
				justify-content: flex-start;
				padding: 1rem;

				> * {
					display: flex;
					align-items: flex-start;
					justify-content: center;
					align-items: center;
					flex: 1.5;
				}

				.recipe-details__difficulty {
					flex: 1;
				}

				.recipe-details__bottom-bar--text {
					margin: 0;
					padding: 0 0.2rem;
					font-size: 14px;
					color: $placeholder-color;
					line-height: 1.5rem;

					@media (min-width: 75rem) {
						font-size: 16px;
					}
				}
			}
		}
	}

	.recipe-details__content {
		flex: 1;

		@media (min-width: 75rem) {
			display: flex;
			flex-direction: column;
			justify-content: space-around;
			background-color: white;
		}

		.recipe-details__ingredients {
			padding: 1rem;
			list-style: none;
			margin-bottom: 3rem;
			background-color: white;

			.recipe-details__ingredient {
				display: flex;
				padding: 0.25rem;
				align-items: baseline;

				.recipe-details__ingredient--bullet {
					width: 0.65rem;
					height: 0.65rem;
					display: flex;
					justify-content: center;
					align-items: center;
					flex-shrink: 0;
					margin: 1rem;
					border-radius: 50%;
					background-color: $primary-color;
				}

				.recipe-details__ingredient--text {
					display: flex;
					align-items: center;
					flex-shrink: 1;
				}
			}
		}

		.recipe-details__instructions {
			padding: 1rem;
			padding-left: 0.25rem;
			list-style: none;
			margin-bottom: 3rem;
			background-color: white;

			.recipe-details__instruction {
				display: flex;
				padding: 0.5rem;
				align-items: baseline;

				.recipe-details__instruction--bullet {
					width: 1.5rem;
					height: 1.5rem;
					display: flex;
					justify-content: center;
					align-items: center;
					flex-shrink: 0;
					margin: 1rem;
					border-radius: 50%;
					background-color: $primary-color;
					font-size: 14px;
					color: white;
				}

				.recipe-details__instruction--text {
					display: flex;
					align-items: flex-start;
					flex-shrink: 1;
					line-height: 1.5rem;
					height: 100%;

					@media (min-width: 75rem) {
						line-height: 3rem;
					}
				}
			}
		}
	}
}
